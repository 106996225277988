import React from "react"
// import {Helmet}  from "react-helmet";
import { useStaticQuery, graphql } from "gatsby"

export const Seo = ({
  description,
  keywords = [],
  title,
  image,
  url,
  author,
  language = "ja",
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title
    ? `${title} | ${site.siteMetadata.title}`
    : site.siteMetadata.title
  const metaImage = image || site.siteMetadata.image
  const metaUrl = url || site.siteMetadata.siteUrl

  const absoluteImageUrl = metaImage?.startsWith("http")
    ? metaImage
    : `${site.siteMetadata.siteUrl}${metaImage}`

  return (
    <>
      <html lang={language} />
      <title>{metaTitle}</title>

      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={keywords.join(",")} />
      <meta name="author" content={author || site.siteMetadata.author} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={metaUrl} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={absoluteImageUrl} />
      <meta property="og:site_name" content={site.siteMetadata.title} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={absoluteImageUrl} />

      <link rel="canonical" href={metaUrl} />
    </>
  )
}
